$button-disabled-background-color: #afafaf

.custom-button-component.MuiButton-root
  border-radius: 20px
  padding: 3px 17px
  text-transform: none

  &.Mui-disabled
    color: white
    background-color: $button-disabled-background-color
