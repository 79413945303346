$background-image-path: "../assets/pexels-alexandr-podvalny-1227513.jpeg"

.top-message-component
  background: linear-gradient(rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5) 100%), url($background-image-path)
  background-size: auto
  background-repeat: no-repeat
  background-position-y: 73%
  background-position-x: 50%
  width: 100%
  color: white
  .top-message-component-wrapper
    max-width: 400px
