.member-cards
  & .MuiCardContent-root, & .MuiCardContent-root:last-child
    padding: 0
    & > div
      margin: 20px
      & > .MuiAvatar-root
        margin: 0 auto
        height: 56px
        width: 56px
      & .MuiLink-root
        color: black
        text-decoration: none
