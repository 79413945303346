.upload-button
  .MuiInputAdornment-root
    margin: 0
    & > .MuiButtonBase-root
      color: black
      border-top-right-radius: 0
      border-bottom-right-radius: 0
      border-color: black
      padding: 17px
      text-transform: none
      margin-right: -1px
      font-size: 1rem
      line-height: inherit
  .upload-button-input
    border-radius: 0 4px 4px 0
    border: 1px solid rgba(0, 0, 0, 0.23)
    padding: 17px 14px

  &.Mui-error
    & .MuiInputAdornment-root > .MuiButtonBase-root
      border-color: red
    & .upload-button-input
      border-color: red
